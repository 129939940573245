<template>
	<div class="home">
		<!-- 一 -->
		
		<div v-if="testShow==true" style="width: 100%;padding: 1rem;display: flex;justify-content: space-between;align-items: center;position: fixed;top: 0;left: 0;background-color: #fbfbfb;box-sizing: border-box;">
			<div></div>
			<div style="background-color: #BFA070;border-radius: .5rem;color: #fff;margin-left: 5rem;width: 7rem;height: 3rem;line-height: 3rem;text-align: center;" @click="tokefu()">Consult Us</div>
			<van-popover v-model:show="showPopover" placement="bottom-end" :actions="actions" @select="onSelect">
			  <template #reference>
			   <img style="width: 4.3rem;" src="../assets/dyy.png" alt="" />
			  </template>
			</van-popover>
		</div>
		
		<div style="padding: 1rem;display: flex;justify-content: space-between;align-items: center;">
			<div></div>
			<div style="background-color: #BFA070;border-radius: .5rem;color: #fff;margin-left: 5rem;width: 7rem;height: 3rem;line-height: 3rem;text-align: center;" @click="tokefu()">Consult Us</div>
			<van-popover v-model:show="showPopover" placement="bottom-end" :actions="actions" @select="onSelect">
			  <template #reference>
			   <img style="width: 4.3rem;" src="../assets/dyy.png" alt="" />
			  </template>
			</van-popover>
		</div>
		<div>
			<img style="width: 100%;" src="../assets/homeen.png" alt="" />
		</div>
		<div class="itemboxs" style="margin-top: -6rem;">
			<p class="itemtitle">
				In this era full of challenges and opportunities, understanding your destiny and taking control of your
				life is increasingly important. With years of experience and outstanding expertise, we are dedicated to
				providing you with the most accurate and detailed astrological analysis to help you make the best
				decisions at every stage of your life.
			</p>
			<div class="boderbox">
				<span class="bodertitle">Our Services</span>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">BaZi Analysis: </span>
					Through your birth chart (BaZi), we provide an in-depth analysis of personal traits, financial
					prospects, career, marriage, and other important aspects of life, helping you find the most suitable
					development direction.
				</p>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">Zi Wei Dou Shu Analysis: </span>
					Decode the secrets of the stars and analyze your astrological chart to offer personalized life
					advice, guiding you through a smoother journey in life.
				</p>
				
				
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">Face and Palm Reading: </span>
					
					
					Explore your personality and destiny through facial features and palm lines, revealing your hidden
					potential and future trends.
				</p>
				
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">Feng Shui Consulting: </span>
					Offer suggestions for home and office Feng Shui layouts to optimize the flow of energy, enhancing
					your luck and bringing good fortune.
				</p>
			</div>
			<div class="boderbox">
				<span class="bodertitle">Why Choose Us?</span>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">Professional Team: </span>
					Our astrologers have many years of practical experience, combining traditional wisdom with modern
					science to provide you with reliable analysis and advice.
				</p>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">Personalized Service: </span>
					Tailored astrological reports based on your individual needs to help you solve the problems you
					encounter in life.
				</p>
				<p class="fs">
					<span style="font-weight: 600;font-size: 1.1rem;">Customer Satisfaction: </span>
					Our services have received praise and trust from many clients, and we are committed to providing a
					high-quality customer experience.
				</p>
			</div>
			<div class="itemtitle" style="margin-top: 1.5rem;">Contact Us</div>
			<div class="itemtitle">Book your exclusive astrological consultation now and explore the limitless
				possibilities of your future!</div>
		</div>
		<!-- 二 -->
		<div class="itembox">
			<p class="itemtitle">
				Traditional Chinese metaphysics, known as Guoxue Mingli, is a part of Chinese traditional culture. It
				involves predicting a person's character, fate, and future trends by observing and analyzing their Ba Zi
				(birth date and time). The study of Ba Zi primarily includes the following aspects:
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
				<span class="bodertitle">Ba Zi Metaphysics</span>
				<p class="fs" style="margin-bottom: 1rem;">
					Also known as Four Pillars of Destiny, Ba Zi is an ancient form of Chinese metaphysics used to
					analyze and predict a person’s destiny and character. It is based on the time and date of a person's
					birth and uses combinations of Yin-Yang, the Five Elements, and the Heavenly Stems and Earthly
					Branches to calculate a person’s destiny pattern. Here is a detailed introduction to Ba Zi:
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Basic Concepts</div>
				<div class="fs">1. Eight Characters and Four Pillars:</div>
				<p class="fs">
					Eight Characters (Ba Zi): Refers to the eight characters formed by four Heavenly Stems and four
					Earthly Branches, namely Year Pillar, Month Pillar, Day Pillar, and Hour Pillar. Each pillar
					consists of one Heavenly Stem and one Earthly Branch.
				</p>
				<p class="fs">
					Four Pillars: Each pillar represents a time segment, namely Year, Month, Day, and Hour.
				</p>
				<div class="fs">2. Heavenly Stems and Earthly Branches:</div>
				<p class="fs">
					Ten Heavenly Stems: Jia, Yi, Bing, Ding, Wu, Ji, Geng, Xin, Ren, Gui.
				</p>
				<p class="fs">
					Twelve Earthly Branches: Zi, Chou, Yin, Mao, Chen, Si, Wu, Wei, Shen, You, Xu, Hai.
				</p>
				<p class="fs">
					Five Elements: Metal, Wood, Water, Fire, Earth. Each Heavenly Stem and Earthly Branch has its
					corresponding Five Element property.
				</p>
				<div class="fs">3. Yin and Yang:</div>
				<p class="fs">
					Heavenly Stems and Earthly Branches are each divided into Yin and Yang attributes. Odd Heavenly
					Stems are Yang, and even ones are Yin; among the Earthly Branches, Zi, Yin, Chen, Wu, Shen, and Xu
					are Yang, while Chou, Mao, Si, Wei, You, and Hai are Yin.
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">Calculation of Ba Zi</div>
				<p class="fs" style="margin-bottom: 2rem;">
					To calculate Ba Zi, you need to know a person's birth date and time, and then use the Chinese lunar
					calendar to determine the corresponding Heavenly Stems and Earthly Branches.
				</p>
				<p class="fs">
					1. Year Pillar: Calculated based on the Heavenly Stem and Earthly Branch of the birth year.
				</p>
				<p class="fs">
					2. Month Pillar: Calculated based on the solar terms of the birth month, combined with the
					stem-branch combination of the birth year.
				</p>
				<p class="fs">
					3. Day Pillar: Calculated based on the Heavenly Stem and Earthly Branch of the birth day.
				</p>
				<p class="fs">
					4. Hour Pillar: Calculated based on the birth time (divided into twelve two-hour segments).
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">Ba Zi Analysis</div>
				<p class="fs" style="margin-bottom: 2rem;">
					Analyzing Ba Zi requires understanding the interactions of the Five Elements, the roles of the Ten
					Gods, and the structure of the destiny pattern.
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">Five Elements Interactions:</div>
				<p class="fs">
					1. Generating Cycle: Metal generates Water, Water generates Wood, Wood generates Fire, Fire
					generates Earth, Earth generates Metal.
				</p>
				<p class="fs">
					2. Overcoming Cycle: Metal overcomes Wood, Wood overcomes Earth, Earth overcomes Water, Water
					overcomes Fire, Fire overcomes Metal.
				</p>
				<div class="baguagj">
					<div style="font-size: 1.1rem;margin-top: .8rem;">Ten Gods:</div>
					<p class="fs">
						1. Direct Resource, Indirect Resource: Representing spirit, knowledge, and elders.
					</p>
					<p class="fs">
						2. Peer, Rob Wealth: Representing siblings and friends.
					</p>
					<p class="fs">
						3. Eating God, Hurting Officer: Representing personality, talents, and children.
					</p>
					<p class="fs">
						4. Direct Wealth, Indirect Wealth: Representing wealth, material possessions, and spouses.
					</p>
					<p class="fs">
						5. Direct Officer, Seven Killings: Representing career, power, and husbands.
					</p>
				</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">Strength of the Destiny Pattern:</div>
				<p class="fs">
					1. By analyzing the quantity and interactions of the Five Elements, you can determine the strength
					of the person's destiny and analyze suitable development directions and potential trends.
				</p>
			</div>
			<div class="itemtitle" style="margin-top: 1.5rem;">Practical Applications</div>
			<p class="itemtitle">
				1. Personality Analysis: Ba Zi can reveal a person’s character traits and behavioral patterns.
			</p>
			<p class="itemtitle">
				2. Career Development: Based on the Officer and Wealth stars in the destiny pattern, you can predict
				career development directions and financial status.
			</p>
			<p class="itemtitle">
				3. Marriage and Relationships: By analyzing the position and role of the Spouse star, you can assess the
				harmony of marriage relationships.
			</p>
			<p class="itemtitle">
				4. Health: Based on the balance of the Five Elements in the destiny pattern, you can determine health
				strengths and potential issues.
			</p>
		</div>
		<div class="newboder">
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
				Precautions
			</p>
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
				Ba Zi is a traditional cultural belief, and interpretations may vary among different schools and
				practitioners. Modern individuals should approach it rationally, using it as a tool for self-reflection
				and planning rather than viewing it as an absolute determination of fate.
			</p>
		</div>

		<!-- 三 -->
		<div class="itembox">
			<p class="itemtitle">
			    Zi Wei Dou Shu, also known as Purple Star Astrology, is a traditional Chinese method of fortune-telling that uses celestial phenomena to predict an individual’s fate and development. Zi Wei Dou Shu is considered a highly systematic and precise astrological system. It combines various factors including constellations, houses, the Five Elements, and Yin-Yang to provide a comprehensive analysis of a person's destiny. Below is a detailed introduction to Zi Wei Dou Shu:
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
			    <span class="bodertitle">Zi Wei Dou Shu</span>

				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Components of Zi Wei Dou Shu</div>
				<div style="font-size: 1.1rem;margin-top: .8rem;">1. Birth Chart:</div>
				<p class="fs">
				    The Zi Wei Dou Shu birth chart consists of twelve houses, each representing different aspects of life, including the Life Palace, Wealth Palace, Career Palace, Spouse Palace, Children Palace, and so on.
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">2. Fourteen Major Stars:</div>
				<p class="fs">
				    The fourteen major stars in Zi Wei Dou Shu are Zi Wei, Tian Ji, Tai Yang, Wu Qu, Tian Tong, Lian Zhen, Tian Fu, Tai Yin, Tan Lang, Ju Men, Tian Xiang, Tian Liang, Qi Sha, and Po Jun.
				</p>
				<p class="fs">
				    Each star has specific attributes and meanings. For example, Zi Wei represents leadership and authority, while Tian Ji symbolizes wisdom and opportunities.
				</p>
				
				<div style="font-size: 1.1rem;margin-top: 2rem;">Auxiliary Stars and Miscellaneous Celestials:</div>
				<p class="fs">
				    1. In addition to the fourteen major stars, there are many auxiliary stars (such as Wen Chang, Wen Qu, Zuo Fu, You Bi) and miscellaneous celestial bodies (such as Mars, Ling Xing). The positions and combinations of these celestial bodies in the birth chart influence the power and meaning of the major stars.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">Twelve Houses:</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    Life Palace, Siblings Palace, Spouse Palace, Children Palace, Wealth Palace, Illness Palace, Travel Palace, Servants Palace, Career Palace, Property Palace, Fortune Palace, Parents Palace. Each house represents a different area of life.
				</p>

				<div class="fengshui">
				    <div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">Fundamental Theories of Zi Wei Dou Shu</div>
				    <div style="font-size: 1.1rem;margin-top: 2rem;">Yin-Yang and the Five Elements:</div>
				    <p class="fs" style="margin-bottom: 2rem;">
				        Celestial bodies and houses are influenced by Yin-Yang and the Five Elements. By analyzing the interactions and conflicts among the Five Elements, one can gain insight into the native's character and the direction of their fate.
				    </p>
				    <div style="font-size: 1.1rem;margin-top: 2rem;">Four Transformations:</div>
				    <p class="fs">
				        The Four Transformations refer to the transformations of Wealth, Authority, Academic, and Misfortune. Each major star can transform into one of these Four Transformations, affecting the native’s fortunes and behavior.
				    </p>

					<p class="fs">
					    1. Wealth Transformation: Represents benefits, gains, and advantages.
					</p>
					<p class="fs">
					    2. Authority Transformation: Represents power, strength, and control.
					</p>
					<p class="fs">
					    3. Academic Transformation: Represents reputation, status, and scholarship.
					</p>
					<p class="fs">
					    4. Misfortune Transformation: Represents obstacles, troubles, and losses.
					</p>
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">Major Periods and Annual Luck:</div>
					<p class="fs">
					    1. Major Periods: A major period spans ten years. Analyzing the combinations of celestial bodies during a major period can reveal the overall fortune for that decade.
					</p>
					<p class="fs">
					    2. Annual Luck: Each year represents an annual luck cycle. Changes in the annual palace affect the specific fortunes of that year.
					</p>
				</div>


				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Applications of Zi Wei Dou Shu</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">1. Personality Analysis</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    By analyzing the positions and combinations of major and auxiliary stars in the birth chart, one can assess the native's personality traits. For example, a person with Zi Wei star in the Life Palace typically has leadership qualities and a strong character, while a person with Tian Ji star in the Life Palace is usually intelligent and adaptable.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">2. Career Development</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    Analyzing the stars and Four Transformations in the Career Palace can predict the native’s career trends and potential challenges. For example, a person with Wu Qu star in the Career Palace often excels in fields such as finance and management.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">3. Financial Status</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    The combination of stars and transformations in the Wealth Palace can reveal the sources of the native’s wealth and financial situation. Wealth Transformation in the Wealth Palace usually indicates good financial luck, while Misfortune Transformation may suggest financial difficulties.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">4. Marriage and Relationships</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    The stars in the Spouse Palace can reveal the state of marital life and the characteristics of a partner. For example, a person with Tai Yin star in the Spouse Palace might encounter a gentle and caring partner.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">5. Health Status</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    The stars and Five Elements in the Illness Palace can reflect the native’s health condition, highlighting strengths and potential health risks.
				</p>


				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Schools of Zi Wei Dou Shu</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    Zi Wei Dou Shu has different schools, such as the Southern School and the Northern School. Each school may have variations in specific analysis and interpretation methods, but they all fundamentally adhere to the same theoretical framework.
				</p>
				<div class="itemtitle" style="margin-top: 1.5rem;">Cautions</div>
				<p class="itemtitle">
				    As a traditional cultural belief, Zi Wei Dou Shu is highly symbolic and interpretative. It emphasizes the diversity and possibilities of fate. When using Zi Wei Dou Shu for self-understanding, modern individuals should consider their actual circumstances and modern scientific knowledge, maintaining a rational and objective attitude.
				</p>
				<p class="itemtitle" style="margin-top: 1.5rem;">
				    Feng Shui is an important aspect of traditional Chinese culture. It studies the relationship between the environment and people, exploring how to adjust and optimize living spaces to achieve good fortune and avoid misfortune. Feng Shui is not just an aspect of architecture and environmental science but also a respect and application of natural laws.
				</p>

			</div>
		</div>

		<!-- 四 -->
		<div class="itembox">

			<div class="boderbox" style="padding-bottom: 3rem;">
				<span class="bodertitle">Feng Shui</span>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">1. Yin-Yang and the Five Elements</div>
				<p class="fs">
				    Yin-Yang: The concept of Yin-Yang in Feng Shui refers to the natural world's dualities and their unity, such as light and darkness, movement and stillness, heat and cold. Balancing Yin and Yang is a fundamental principle of Feng Shui arrangement.
				</p>
				<p class="fs">
				    Five Elements: The Five Elements (Metal, Wood, Water, Fire, Earth) constitute all things and influence the environment and fortunes through their interactions of generation and overcoming. The generating cycle: Metal generates Water, Water generates Wood, Wood generates Fire, Fire generates Earth, Earth generates Metal; the overcoming cycle: Metal overcomes Wood, Wood overcomes Earth, Earth overcomes Water, Water overcomes Fire, Fire overcomes Metal.
				</p>
				<div style="font-size: 1.1rem;margin-top: .8rem;">2. Directions and the Eight Trigrams</div>
				<p class="fs">
				    Directions: Feng Shui emphasizes the impact of a building's orientation and location on individuals. The eight directions combined with the Five Elements and Eight Trigrams form a crucial theoretical foundation for Feng Shui.
				</p>
				<p class="fs">
				    Eight Trigrams: The Eight Trigrams (Qian, Dui, Li, Zhen, Xun, Kan, Gen, Kun) each represent different directions and attributes. The Trigram chart is used to analyze and predict the Feng Shui conditions of an environment.
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">Schools of Feng Shui</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">1. Form School</div>
				<p class="fs">
				    The Form School focuses on landforms and terrain, emphasizing the shape and flow of mountains and water. This school believes that the natural topography (such as mountains and rivers) has a critical impact on Feng Shui.
				</p>
				<p class="fs">
				    Landforms: Refers to the undulations, heights, and straightness of the terrain. Favorable landforms can gather energy and promote vitality, whereas unfavorable ones may lead to disadvantages.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">2. Compass School</div>
				<p class="fs">
				    The Compass School emphasizes the dynamic changes in directions, time, and energy flow, using compasses and time to arrange Feng Shui.
				</p>
				<p class="fs">
				    Flying Stars: One of the key methods in the Compass School, adjusting Feng Shui arrangements based on changes in time (such as years, months, and days).
				</p>
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Applications of Feng Shui</div>
				<div style="font-size: 1.1rem;margin-top: 2rem;">1. Home Feng Shui</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    Site Selection and Orientation: The location and orientation of a house should consider the surrounding environment, such as proximity to mountains or water flow.
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
				    Interior Layout: Feng Shui emphasizes the arrangement of rooms within a house, such as the positions of the bedroom, kitchen, and living room, to ensure smooth energy flow, adequate lighting, and balance of the Five Elements.
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
				    Furniture Placement: Furniture placement should follow Feng Shui principles, such as avoiding placing the head of the bed towards the door and ensuring the desk does not face away from the door.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">2. Office Feng Shui</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    Office Location: Choosing an appropriate office location and floor can enhance work efficiency and career prospects.
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
				    Desk Layout: The placement and orientation of the office desk should consider the owner’s personal Trigram and direction to boost work fortune.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">3. Residential and Ancestral Feng Shui</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    Residential Feng Shui: Refers to the Feng Shui of homes where living people reside, directly impacting the occupants' health, wealth, and interpersonal relationships.
				</p>
				<p class="fs" style="margin-bottom: 2rem;">
				    Ancestral Feng Shui: Refers to the Feng Shui of cemeteries and tombs, believed to affect the fortunes of descendants. Thus, site selection and layout are especially important.
				</p>

				<div class="shanshui">
				    <div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Principles of Feng Shui</div>
				    <div style="font-size: 1.1rem;margin-top: 2rem;">1. Concealing the Wind and Gathering the Qi</div>
				    <p class="fs" style="margin-bottom: 2rem;">
				        Feng Shui emphasizes "Concealing the Wind and Gathering the Qi," which means designing the environment to maintain stable and smooth energy flow, ensuring that the living space is vibrant and full of vitality.
				    </p>
				    <div style="font-size: 1.1rem;margin-top: 2rem;">2. Harmony Between Heaven and Humanity</div>
				    <p class="fs" style="margin-bottom: 2rem;">
				        Feng Shui stresses the harmonious coexistence between humans and nature. By adjusting the environment to align with natural laws, it aims to improve quality of life and fortune.
				    </p>
				    <div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Modern Applications of Feng Shui</div>
				    <p class="fs" style="margin-bottom: 2rem;">
				        In modern architecture and interior design, Feng Shui is often used to enhance environmental comfort and increase positive energy. Through thoughtful design, Feng Shui can help individuals achieve better health, wealth, and happiness.
				    </p>
				</div>
				<div class="itemtitle" style="margin-top: 1.5rem;">Cautions</div>
				<p class="itemtitle">
				    Feng Shui has cultural and belief aspects. In modern times, when applying Feng Shui, it is important to combine scientific knowledge and personal circumstances, and to view Feng Shui's role rationally without over-reliance.
				</p>

			</div>
		</div>

		<!-- 五 -->
		<div class="itembox">
			<p class="itemtitle">
			    Facial and palm readings are two important methods in traditional Chinese astrology used to analyze and predict a person's character, destiny, and fortune. These methods provide insights into a person's personality and fate through the observation of facial features and palm lines. Below is a detailed introduction to facial and palm readings:
			</p>
			<div class="boderbox" style="padding-bottom: 3rem;">
			    <span class="bodertitle">Facial and Palm Readings</span>

				<div style="font-size: 1.1rem;margin-top: 2rem;">Eyebrows (Longevity Officer):</div>
				<p class="fs">
				    1. Shape: Neatly arranged eyebrows usually indicate a gentle personality with strong will; disordered or sparse eyebrows may suggest emotional instability and a life with many ups and downs.
				</p>
				<p class="fs">
				    2. Length: Eyebrows extending beyond the corner of the eyes generally signify a long life and deep sibling relationships.
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">Eyes (Inspector Officer):</div>
				<p class="fs">
				    1. Size: Large eyes often indicate a broad mind and rich emotions; small eyes may suggest a meticulous and thoughtful nature.
				</p>
				<p class="fs">
				    2. Shape: Round eyes represent enthusiasm, almond-shaped eyes signify intelligence, and narrow eyes symbolize rationality and calmness.
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">Nose (Wealth Officer):</div>
				<p class="fs">
				    1. Height: A high and prominent nose generally suggests confidence and strong opinions, while a flat nose may indicate a lack of self-confidence.
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">Mouth (Treasurer Officer):</div>
				<p class="fs">
				    1. Size: A large mouth often indicates a person with strong social skills and inclusiveness, while a small mouth may suggest a more reserved nature.
				</p>
				<p class="fs">
				    2. Shape: Thick lips are associated with rich emotions, whereas thin lips suggest rationality and self-control.
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">Ears (Hearing Officer):</div>
				<p class="fs">
				    1. Thickness: Thick ears generally indicate good health and longevity, while thin ears may suggest weaker health.
				</p>
				<p class="fs">
				    2. Position: Ears higher than the eyebrows suggest intelligence and a love for learning; low-set ears are often associated with a gentle disposition.
				</p>
				
				<div style="font-size: 1.1rem;margin-top: .8rem;">Facial Regions</div>
				<p class="fs">
				    Facial reading divides the face into various regions, each corresponding to different stages and fortunes in life.
				</p>
				<p class="fs">
				    1. Forehead: Represents early life fortunes. A broad and smooth forehead suggests a smooth early life and a successful career start.
				</p>
				<p class="fs">
				    2. Cheekbones: Symbolize power and influence. High cheekbones often indicate leadership qualities.
				</p>
				<p class="fs">
				    3. Chin: Related to late-life fortunes. A rounded chin suggests a happy and comfortable old age.
				</p>

				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 2rem;">Observing Complexion</div>
				<p class="fs">
				    The complexion of the face is also considered a reflection of a person's fortune. A rosy complexion often indicates vitality and smooth fortune; a dull complexion may suggest recent low fortune and the need for extra attention.
				</p>
				<span class="bodertitle" style="margin-top: 2.5rem;">Palmistry</span>
				
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Palm Lines</div>
				<p class="fs">
				    In palmistry, the three major lines are the Life Line, Head Line, and Heart Line. Additionally, there are minor lines such as the Marriage Line and Career Line.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">Life Line:</div>
				<p class="fs">
				    1. Position: Located between the thumb and index finger, curving towards the base of the palm.
				</p>
				<p class="fs">
				    2. Significance: A long Life Line generally indicates good health and vitality; a short Life Line may suggest weaker health. Deep lines indicate good health, while shallow lines may suggest low energy.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">Head Line:</div>
				<p class="fs">
				    1. Position: Starts between the index and middle fingers, crossing the palm horizontally.
				</p>
				<p class="fs">
				    2. Significance: A long and clear Head Line usually signifies quick thinking, while a short Head Line may indicate straightforward and simple thought processes.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">Heart Line:</div>
				<p class="fs">
				    1. Position: Starts on the side of the little finger, extending towards the index finger.
				</p>
				<p class="fs">
				    2. Significance: A deep and long Heart Line often indicates rich emotions and sensitivity, while a short Heart Line may suggest a more reserved emotional nature.
				</p>
				<div style="font-size: 1.1rem;margin-top: 2rem;">Finger Shapes</div>
				<p class="fs">
				    1. Thumb: A long thumb usually indicates strong willpower and leadership qualities.
				</p>
				<p class="fs">
				    2. Index Finger: A long index finger often signifies confidence and a desire for control.
				</p>
				<p class="fs">
				    3. Middle Finger: A long middle finger usually indicates patience and a sense of responsibility.
				</p>
				<p class="fs">
				    4. Ring Finger: A long ring finger often suggests artistic talent.
				</p>
				<p class="fs">
				    5. Little Finger: A long little finger is associated with good communication skills and strong social abilities.
				</p>

				<div class="shouxiang">
				    <div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Palm Characteristics</div>
					<div style="font-size: 1.1rem;margin-top: 2rem;">Palm Thickness:</div>
					<p class="fs" style="margin-bottom: 2rem;">
					    1. Thick: Typically represents good health and a practical approach to tasks.
					</p>
					<p class="fs" style="margin-bottom: 2rem;">
					    2. Thin: May indicate a weaker constitution and a sensitive personality.
					</p>
					
					<div style="font-size: 1.1rem;margin-top: 2rem;">Palm Size:</div>
					<p class="fs" style="margin-bottom: 2rem;">
					    1. Large Palm: Suggests an extroverted personality with a focus on action.
					</p>
					<p class="fs" style="margin-bottom: 2rem;">
					    2. Small Palm: Indicates a detail-oriented thinker with a focus on contemplation.
					</p>
				</div>


				
				<div style="font-weight: 600;font-size: 1.1rem;margin-top: 1.5rem;">Applications of Palmistry</div>
				<p class="fs" style="margin-bottom: 2rem;">
				    Palmistry is often used to analyze personal traits, health conditions, and career development. Various palm features can indicate potential challenges and opportunities at different stages of life.
				</p>
				<div class="itemtitle" style="margin-top: 1.5rem;">Cautions</div>
				<p class="itemtitle">
				    As part of traditional culture, facial and palm reading carry symbolic and interpretative aspects. In modern applications, it should be integrated with personal circumstances and scientific knowledge, and not relied upon excessively.
				</p>

			</div>

		</div>

		<div class="newboders">
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
			    Conclusion
			</p>
			<p style="font-size: 1.1rem;line-height: 1.6;margin-top: .5rem;">
			    Traditional Chinese astrology is rooted in a rich philosophical and historical framework, emphasizing harmony between humans, nature, and the universe. As both a cultural heritage and a philosophical approach, it continues to hold significant influence and research value in modern society. However, it is essential to approach astrology with scientific and rational analysis, recognizing that perspectives on it may vary among individuals.
			</p>

		</div>

	</div>
</template>

<script setup>

	import { ref , onMounted } from 'vue';
	import { showToast } from 'vant';
	import {useRouter} from 'vue-router';
	import axios from 'axios'
	
	const router = useRouter();
	const showPopover = ref(false);
	const testShow = ref(false);
	const kefu = ref('');
	    // 通过 actions 属性来定义菜单选项
	    const actions = [
	      { text: 'English' },
	      { text: '中文简体' },
	      { text: '中文繁體' },
	    ];
	    const onSelect = (action) => {
			console.log(action.text);
			if(action.text == 'English') {
				router.push('/Homeen')
			}
			if(action.text == '中文简体') {
				router.push('/')
			}
			if(action.text == '中文繁體') {
				router.push('/Homezh')
			}
		};
		onMounted(()=>{
			scrollTo(0, 0)
			window.addEventListener("scroll", handleScroll);
		});
		// 城市板块动画
		function handleScroll(e) {
		  let currentScroll = window.pageYOffset; //表示当前滚动的位置
		  let viewHeight = window.innerHeight;//视口高度
		  if (currentScroll > 100) {
		    testShow.value = true;
		  } else {
		    testShow.value = false;
		  }
		  // console.log("testShow", testShow.value);
		}
		const getUsers = async () => {
		  try {
		    const response = await axios.get('https://www.stardust001.com/get.php')
		    return response.data
		  } catch (error) {
		    console.error(error)
		  }
		}
		 getUsers().then(data => {
		      kefu.value = data
		    })
		function tokefu() {
			// console.log(kefu.value);
			window.location.href = kefu.value
		}
</script>
<style lang="less" scoped>
	.fs {
		font-size: 1.1rem;
		line-height: 1.6;
		margin-top: 1rem;
	}

	.home {
		box-sizing: border-box;
		// min-height: 100vh;
		width: 100%;
		min-height: 150vh;
		background: url(../assets/homebj.png) no-repeat;
		background-size: 100% 100%;
		overflow-y: scroll;

		.title {
			text-align: center;
			font-family: '楷体';
			font-weight: 600;
			font-size: 1.5rem;
		}

		.fengshui {
			background: url(../assets/fengshui.png) no-repeat;
			background-size: 18rem 17rem;
			background-position: top right;
		}

		.shouxiang {
			background: url(../assets/shouxiang.png) no-repeat;
			background-size: 70% 100%;
			background-position: top right;
		}

		.itembox,
		.itemboxs {
			margin-top: 2rem;
			// width: 100%;
			min-height: 40rem;
			background: url(../assets/newitembj.png) no-repeat;
			background-size: 100% 100%;
			// background-position: center center;
			// background-position: .25rem 0rem;

			padding: 4rem;
			padding-bottom: 6rem;

			.itemtitle {
				margin: 0 auto;
				color: #af874b;
				font-size: 1.1rem;
				line-height: 1.6;
			}

			.boderbox {
				margin-top: 2rem;
				min-height: 20rem;
				border: 1px solid #e4d9ce;
				padding: 1rem;

				.bodertitle {
					display: inline-block;
					background: url(../assets/tixtbj.png) no-repeat;
					background-size: 100% 100%;
					padding: .5rem 1.5rem;
					color: #fff;
				}

				.baguabj {
					background: url(../assets/bgbg.png) no-repeat;
					background-size: 13rem 12rem;
					background-position: bottom right;
				}
			}
		}

		.itemboxs {
			background: url(../assets/itembj.png) no-repeat !important;
			background-size: 100% 100% !important;



		}

		.newboder {
			width: 90%;
			min-height: 10rem;
			margin-left: 5%;
			background: url(../assets/newboder.png) no-repeat !important;
			background-size: 100% 100% !important;
			padding: 1.5rem 2rem;
			box-sizing: border-box;

		}

		.newboders {
			width: 90%;
			min-height: 10rem;
			margin-left: 5%;
			background: url(../assets/botttom.png) no-repeat !important;
			background-size: 100% 100% !important;
			padding: 1.5rem 2rem;
			box-sizing: border-box;
			margin-bottom: 9rem;
			margin-top: 3rem;

		}
	}
</style>