import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Homeen from '../views/Home-en.vue'
import Homezh from '../views/Home-zh.vue'

const routes = [
  {
    path: '/',
    name: 'Homeen',
    component: Homeen
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Homezh',
    name: 'Homezh',
    component: Homezh
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
